import { useMagicKeys } from '@vueuse/core'
import { watch, Plugin, effectScope } from 'vue'
import { useMutationUserUpdate } from '@/api/useUsers'
import { useCurrentUser } from '@/modules/base/composable/useCurrentUser'

export const lightTheme: Plugin = {
  install(app) {
    const scope = effectScope()

    app.runWithContext(() => {
      scope.run(() => {
        const { currentUser } = useCurrentUser()

        watch(
          () => currentUser.value?.useLightTheme,
          (useLightTheme) => {
            const className = 'light'
            if (useLightTheme) {
              document.documentElement.classList.add(className)
            } else {
              document.documentElement.classList.remove(className)
            }
          },
          { immediate: true }
        )

        /**
         * This DEV only feature allows you to quickly switch between light and dark mode
         * by pressing `Ctrl + L` keyboard shortcut
         */
        if (import.meta.env.DEV) {
          const { current } = useMagicKeys()
          const { mutate } = useMutationUserUpdate()

          watch(current, (key) => {
            if (key.has('l') && key.has('control')) {
              mutate({
                id: currentUser.value?.id,
                useLightTheme: !currentUser.value?.useLightTheme,
              })
            }
          })
        }
      })
    })
  },
}
